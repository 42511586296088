<template>
  <b-col
    id="data-produk"
    class="pl-0 pr-0"
  >
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <b-row class="d-flex justify-content-end align-items-center mt-2 mr-50 wrapper__filter__data__product">
              <b-col
                md="3"
                class="text-center"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchProduct"
                    placeholder="Example"
                    @input="getProduct"
                  />
                </b-input-group>
              </b-col>
              <b-col md="auto">
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  right
                  no-caret
                  variant="primary"
                >
                  <template
                    #button-content
                  >
                    <feather-icon icon="SlidersIcon" />
                  </template>
                  <b-dropdown-form
                    style="width: 417px;"
                  >
                    <b-form @submit.prevent>
                      <b-row>
                        <b-col
                          cols="12"
                          class="ml-50"
                        >
                          <b-form-group
                            label="Stok"
                          >
                            <div class="d-flex justify-content-center align-items-center">
                              <b-form-input
                                v-model="stockFrom"
                                class=""
                              />
                              <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                disabled
                              >
                                <feather-icon
                                  icon="MinusIcon"
                                />
                              </b-button>
                              <b-form-input
                                v-model="stockTo"
                                class="mr-1"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          class="ml-50 mt-50"
                        >
                          <b-form-group
                            label="Terjual"
                          >
                            <div class="d-flex justify-content-center align-items-center">
                              <b-form-input
                                v-model="soldFrom"
                                class=""
                              />
                              <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                disabled
                              >
                                <feather-icon
                                  icon="MinusIcon"
                                />
                              </b-button>
                              <b-form-input
                                v-model="soldTo"
                                class="mr-1"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- submit and reset -->
                        <b-col
                          cols="12"
                          class="ml-50 mt-1"
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-primary"
                            class="mr-1"
                            @click="resetFilter"
                          >
                            Reset
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            @click="getProduct"
                          >
                            Terapkan
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-dropdown-form>
                </b-dropdown>
              </b-col>
            </b-row>

            <!-- Mobile -->
            <b-row class="d-flex justify-content-end align-items-center mt-2 mx-50 wrapper__filter__data__product__mobile">
              <b-col
                cols="8"
                class="text-center"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchProduct"
                    placeholder="Example"
                    @input="getProduct"
                  />
                </b-input-group>
              </b-col>
              <b-col cols="4">
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  right
                  no-caret
                  variant="primary"
                >
                  <template
                    #button-content
                  >
                    <feather-icon icon="SlidersIcon" />
                  </template>
                  <b-dropdown-form
                    class="wrapper__form__filter__data__product__mobile"
                    style="width: 417px;"
                  >
                    <b-form @submit.prevent>
                      <b-row>
                        <b-col
                          cols="12"
                          class="ml-50"
                        >
                          <b-form-group
                            label="Stok"
                          >
                            <div class="d-flex justify-content-center align-items-center">
                              <b-form-input
                                v-model="stockFrom"
                                class=""
                              />
                              <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                disabled
                              >
                                <feather-icon
                                  icon="MinusIcon"
                                />
                              </b-button>
                              <b-form-input
                                v-model="stockTo"
                                class="mr-1"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          class="ml-50 mt-50"
                        >
                          <b-form-group
                            label="Terjual"
                          >
                            <div class="d-flex justify-content-center align-items-center">
                              <b-form-input
                                v-model="soldFrom"
                                class=""
                              />
                              <b-button
                                class="btn-icon"
                                variant="flat-dark"
                                disabled
                              >
                                <feather-icon
                                  icon="MinusIcon"
                                />
                              </b-button>
                              <b-form-input
                                v-model="soldTo"
                                class="mr-1"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- submit and reset -->
                        <b-col
                          cols="12"
                          class="ml-50 mt-1"
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-primary"
                            class="mr-1"
                            @click="resetFilter"
                          >
                            Reset
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            @click="getProduct"
                          >
                            Terapkan
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-dropdown-form>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-row class="mt-1">
      <b-col
        cols="12"
        class=""
      >
        <b-overlay
          variant="light"
          :show="loading"
          spinner-variant="primary"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <!-- Desktop -->
          <b-container
            fluid
          >
            <b-row>
              <b-col
                cols="3"
              >
                <strong class="ml-2">
                  Nama Produk
                </strong>
              </b-col>
              <b-col cols="2">
                <strong class="ml-2">
                  Variasi
                </strong>
              </b-col>
              <b-col cols="2">
                <strong class="ml-2">
                  Harga
                </strong>
              </b-col>
              <b-col cols="2">
                <strong class="ml-2">
                  Stock
                </strong>
              </b-col>
              <b-col cols="1">
                <strong>
                  Terjual
                </strong>
              </b-col>
              <b-col cols="2">
                <strong class="ml-2">
                  Aksi
                </strong>
              </b-col>
            </b-row>
            <hr style="height:1px; background-color:#828282; color: #828282; opacity: 0.5;">
            <div v-if="variantData.length > 0">
              <div
                v-for="(itemsData, index) in variantData"
                :key="index+1"
                class="my-[20px]"
              >
                <b-row>
                  <b-col
                    cols="3"
                  >
                    <b-row class="ml-2">
                      <b-container
                        fluid
                        class="d-flex"
                      >
                        <b-img
                          v-if="itemsData.product_image[0] !== undefined"
                          :src="itemsData.product_image[0].images_path"
                          fluid
                          class="image-product"
                        />
                        <b-img
                          v-else
                          :src="require('@/assets/images/avatars/image-null.png')"
                          fluid
                          width="50"
                          class="image-product"
                        />
                        <div class="ml-1">
                          <div class="font-medium">{{ itemsData.product_name }}</div>
                          <div class="text-primary text-[12px]">SKU: {{ itemsData.sku }}</div>
                        </div>
                      </b-container>
                    </b-row>
                  </b-col>
                  <b-col
                    v-if="itemsData.variants[0] !== undefined"
                    cols="2"
                    class=""
                  >
                    <div
                      v-for="(itemsVariant, indexVariant) in itemsData.variants.slice(0,3)"
                      :key="indexVariant+1"
                    >
                      <p class="ml-2">
                        {{ itemsVariant.variant }}
                      </p>
                    </div>
                  </b-col>
                  <b-col
                    v-else
                    cols="2"
                    class=""
                  >
                    <p class="ml-2">
                      -
                    </p>
                  </b-col>
                  <b-col
                    v-if="itemsData.variants[0] !== undefined"
                    cols="2"
                    class=""
                  >
                    <div
                      v-for="(itemsVariant, indexVariant) in itemsData.variants.slice(0,3)"
                      :key="indexVariant+1"
                    >
                      <p class="ml-2">
                        Rp {{ formatPrice(itemsVariant.price) }}
                      </p>
                    </div>
                  </b-col>
                  <b-col
                    v-else
                    cols="2"
                    class=""
                  >
                    <p class="ml-2">
                      Rp {{ formatPrice(itemsData.price) }}
                    </p>
                  </b-col>
                  <b-col
                    v-if="itemsData.variants[0] !== undefined"
                    cols="2"
                    class=""
                  >
                    <div
                      v-for="(itemsVariant, indexVariant) in itemsData.variants.slice(0,3)"
                      :key="indexVariant+1"
                    >
                      <p class="ml-2">
                        {{ itemsVariant.stock }}
                      </p>
                    </div>
                  </b-col>
                  <b-col
                    v-else
                    cols="2"
                    class=""
                  >
                    <p class="ml-2">
                      {{ itemsData.stock }}
                    </p>
                  </b-col>
                  <b-col
                    v-if="itemsData.variants[0] !== undefined"
                    cols="1"
                    class=""
                  >
                    <div
                      v-for="(itemsVariant, indexVariant) in itemsData.variants.slice(0,3)"
                      :key="indexVariant+1"
                    >
                      <p class="ml-2">
                        {{ itemsVariant.sold }}
                      </p>
                    </div>
                  </b-col>
                  <b-col
                    v-else
                    cols="1"
                    class=""
                  >
                    <p class="ml-2">
                      {{ itemsData.sold }}
                    </p>
                  </b-col>
                  <b-col
                    cols="2"
                    class=""
                  >
                    <b-button
                      v-if="itemsData.is_bundling === '0'"
                      v-b-tooltip.hover.top="'Edit'"
                      class="btn-icon"
                      size="sm"
                      variant="flat-dark"
                      @click="checkProduct('edit', itemsData.product_id)"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/edit.svg"
                        alt="edit"
                        width="16"
                      >
                    </b-button>
                    <b-button
                      v-if="itemsData.is_bundling === '1'"
                      v-b-tooltip.hover.top="'Edit'"
                      class="btn-icon"
                      size="sm"
                      variant="flat-dark"
                      @click="checkBundling('edit', itemsData.product_id)"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/edit.svg"
                        alt="edit"
                        width="16"
                      >
                    </b-button>
                    <b-button
                      v-if="itemsData.is_bundling === '0'"
                      v-b-tooltip.hover.top="'Bundling'"
                      class="btn-icon"
                      size="sm"
                      variant="flat-dark"
                      tag="router-link"
                      :to="{ name: $route.meta.routeBundling, params: { product_id: itemsData.product_id } }"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/gift.svg"
                        alt="bundling"
                        width="16"
                      >
                    </b-button>
                    <b-button
                      v-if="itemsData.is_bundling === '0'"
                      v-b-tooltip.hover.top="'Hapus'"
                      class="btn-icon"
                      size="sm"
                      variant="flat-dark"
                      @click="checkProduct('delete', itemsData.product_id)"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                        alt="trash"
                        width="16"
                      >
                    </b-button>
                    <b-button
                      v-if="itemsData.is_bundling === '1'"
                      v-b-tooltip.hover.top="'Hapus'"
                      class="btn-icon"
                      size="sm"
                      variant="flat-dark"
                      @click="checkBundling('delete', itemsData.product_id)"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                        alt="trash"
                        width="16"
                      >
                    </b-button>
                  </b-col>
                </b-row>
                <b-col
                  cols="12"
                  class="p-0"
                >
                  <b-collapse
                    :id="`collapse-${String(index)}`"
                    class=""
                  >
                    <b-row>
                      <b-col cols="3" />
                      <b-col
                        cols="2"
                      >
                        <div
                          v-for="(itemsVariant, indexVariant) in itemsData.variants.slice(3,itemsData.variants.length)"
                          :key="indexVariant+1"
                        >
                          <p class="ml-2">
                            {{ itemsVariant.variant }}
                          </p>
                        </div>
                      </b-col>
                      <b-col
                        cols="2"
                      >
                        <div
                          v-for="(itemsVariant, indexVariant) in itemsData.variants.slice(3,itemsData.variants.length)"
                          :key="indexVariant+1"
                        >
                          <p class="ml-2">
                            Rp {{ formatPrice(itemsVariant.price) }}
                          </p>
                        </div>
                      </b-col>
                      <b-col
                        cols="2"
                      >
                        <div
                          v-for="(itemsVariant, indexVariant) in itemsData.variants.slice(3,itemsData.variants.length)"
                          :key="indexVariant+1"
                        >
                          <p class="ml-2">
                            {{ itemsVariant.stock }}
                          </p>
                        </div>
                      </b-col>
                      <b-col
                        cols="1"
                      >
                        <div
                          v-for="(itemsVariant, indexVariant) in itemsData.variants.slice(3,itemsData.variants.length)"
                          :key="indexVariant+1"
                        >
                          <p class="ml-2">
                            {{ itemsVariant.sold }}
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </b-col>
                <b-row
                  v-if="itemsData.is_bundling === '1'"
                  class="pl-[7rem]"
                >
                  <b-col cols="12">
                    <div class="flex mt-[4px]">
                      <div
                        v-b-toggle="`bundling-${String(index)}`"
                        class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                      >
                        <img
                          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                          alt="bundling"
                          width="16"
                        >
                        <div>Bundling</div>
                      </div>
                    </div>
                    <b-collapse
                      :id="`bundling-${String(index)}`"
                    >
                      <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                        <div class="border rounded-t w-[80%]">
                          <div class="bg-[#FFECE9] py-[8px] px-[28px] rounded-t text-[#6e6b7b] font-[500]">
                            Produk
                          </div>
                          <b-table
                            :items="itemsData.bundle_component"
                            :fields="fieldsBundling"
                          >
                            <template #cell(no)="data">
                              {{ data.index + 1 }}
                            </template>
                            <template #cell(product_name)="data">
                              <div class="flex gap-[16px] items-start">
                                <img
                                  :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                                  alt="product"
                                  width="52"
                                  height="52"
                                >
                                <div class="space-y-[4px]">
                                  <div class="text-[#6e6b7b] font-medium">
                                    {{ data.item.product_name }}
                                  </div>
                                  <div class="text-primary text-[12px]">
                                    SKU: {{ data.item.sku }}
                                  </div>
                                </div>
                              </div>
                            </template>
                          </b-table>
                        </div>
                      </div>
                      <div class="flex ml-[0.8rem]">
                        <div
                          v-if="itemsData.is_bundling === '1'"
                          v-b-toggle="`bundling-${String(index)}`"
                          class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                        >
                          <feather-icon
                            icon="ChevronUpIcon"
                          />
                          <div>Tutup</div>
                        </div>
                      </div>
                    </b-collapse>
                  </b-col>
                </b-row>
                <b-row
                  v-if="itemsData.variants.length > 3"
                  class="d-flex justify-content-end mb-2"
                >
                  <b-col cols="auto">
                    <div>
                      <b-button
                        v-b-toggle="`collapse-${String(index)}`"
                        variant="flat-dark"
                        size="sm"
                      >
                        <span class="when-opened">
                          Tutup
                        </span>
                        <span class="when-closed">
                          Tampilkan variasi lainnya
                        </span>
                        <feather-icon
                          icon="ChevronDownIcon"
                          class="when-closed"
                        />
                        <feather-icon
                          icon="ChevronUpIcon"
                          class="when-opened"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <hr class="mt-[20px]">
              </div>
            </div>
            <div v-else>
              <b-col
                cols="12"
                class="text-center"
              >
                <h5 class="p-1">
                  <strong>
                    Tidak ada data untuk ditampilkan.
                  </strong>
                </h5>
              </b-col>
            </div>
          </b-container>
        </b-overlay>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BImg,
  BAvatar,
  VBPopover,
  BOverlay,
  VBToggle,
  BModal,
  BDropdown,
  BDropdownForm,
  BTable,
  BContainer,
  BCollapse,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { heightTransition } from '@core/mixins/ui/transition'
import { komshipAxiosIns } from '@/libs/axios'
import { fieldsBundling } from './config'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BImg,
    BForm,
    BOverlay,
    BDropdown,
    BDropdownForm,
    BTable,
    BContainer,
    BCollapse,
  },
  directives: {
    'b-popover': VBPopover,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),

      defaultFilter: 0,
      headVariant: null,
      idDelete: '',
      loading: false,
      variantFieldsTable: [
        {
          key: 'variant',
          label: 'Variasi',
          thClass: 'border-0 bg-white',
          tdClass: 'border-0',
        },
      ],
      variantItems: [[]],
      variantItemsExpand: [[]],
      variantData: [],
      fields: [
        {
          key: 'product_name', label: 'Nama Produk',
        },
        {
          key: 'variants',
          label: 'Variasi',
        },
        {
          key: 'price',
          label: 'Harga',
        },
        {
          key: 'stock',
          label: 'Stock',
        },
        {
          key: 'sold',
          label: 'Terjual',
        },
        {
          key: 'action', label: 'Aksi', class: 'col-action',
        },
      ],
      fieldsBundling,
      items: [],
      imageFileProduct: null,
      searchProduct: '',
      expandCollapseIsActive: false,
      // Filter
      name: '',
      stockFrom: '',
      stockTo: '',
      soldFrom: '',
      soldTo: '',
      limit: 50,
      offset: 0,
      lastOrderProduct: false,
    }
  },
  mounted() {
    this.fetchProduct()
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('data-produk').offsetHeight && !this.loading) {
        this.getNextProduct()
      }
    }
  },
  methods: {
    fetchProduct() {
      this.offset = 0
      this.loading = true
      const params = {
        status: 1,
        limits: this.limit,
        offset: this.offset,
      }
      if (this.searchProduct) Object.assign(params, { name: this.searchProduct })
      if (this.soldFrom) Object.assign(params, { soldFrom: this.soldFrom })
      if (this.soldTo) Object.assign(params, { soldTo: this.soldTo })
      if (this.stockFrom) Object.assign(params, { stockFrom: this.stockFrom })
      if (this.stockTo) Object.assign(params, { stockTo: this.stockTo })
      komshipAxiosIns.get('/v4/product', {
        params,
      }, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        const { data } = response.data
        this.variantData = data
        if (data.length < this.limit) {
          this.lastOrderProduct = true
        } else {
          this.lastOrderProduct = false
        }
        this.offset = data.length
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal me-load produk, silahkan coba lagi!',
            variant: 'danger',
          },
        })
      })
    },
    getProduct: _.debounce(function () {
      this.loading = true
      this.limit = 50
      this.offset = 0
      const params = {
        status: 1,
        limits: this.limit,
        offset: this.offset,
      }
      if (this.searchProduct) Object.assign(params, { name: this.searchProduct })
      if (this.soldFrom) Object.assign(params, { soldFrom: this.soldFrom })
      if (this.soldTo) Object.assign(params, { soldTo: this.soldTo })
      if (this.stockFrom) Object.assign(params, { stockFrom: this.stockFrom })
      if (this.stockTo) Object.assign(params, { stockTo: this.stockTo })
      komshipAxiosIns.get('/v4/product', {
        params,
      }, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        const { data } = response.data
        this.variantData = data
        if (data.length < this.limit) {
          this.lastOrderProduct = true
        } else {
          this.lastOrderProduct = false
        }
        this.offset = data.length
        this.loading = false
        // return this.variantData
      }).catch(() => {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal me-load produk, silahkan coba lagi!',
            variant: 'danger',
          },
        })
      })
    }, 1000),
    getNextProduct() {
      if (!this.lastOrderProduct) {
        this.loading = true
        const params = {
          status: 1,
          limits: this.limit,
          offset: this.offset,
        }
        if (this.searchProduct) Object.assign(params, { name: this.searchProduct })
        if (this.soldFrom) Object.assign(params, { soldFrom: this.soldFrom })
        if (this.soldTo) Object.assign(params, { soldTo: this.soldTo })
        if (this.stockFrom) Object.assign(params, { stockFrom: this.stockFrom })
        if (this.stockTo) Object.assign(params, { stockTo: this.stockTo })
        komshipAxiosIns.get('/v4/product', {
          params,
        }, {
          headers: { Authorization: `Bearer ${useJwt.getToken()}` },
        }).then(response => {
          const { data } = response.data
          this.variantData.push(...data)
          this.offset += data.length
          this.loading = false
          if (data.length < this.limit) {
            this.lastOrderProduct = true
          } else {
            this.lastOrderProduct = false
          }
          return this.variantData
        }).catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal me-load produk, silahkan coba lagi!',
              variant: 'danger',
            },
          })
        })
      }
    },
    deleteProduct(id) {
      komshipAxiosIns.delete(`/v1/product/delete/${id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            text: 'Success hapus produk',
            variant: 'success',
          },
        })
        this.getProduct()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal hapus produk, silahkan coba lagi!',
            variant: 'danger',
          },
        })
      })
    },
    deleteBundling(id) {
      komshipAxiosIns.delete(`/v1/product/bundling/delete/${id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            text: 'Success hapus produk',
            variant: 'success',
          },
        })
        this.getProduct()
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal hapus produk, silahkan coba lagi!',
            variant: 'danger',
          },
        })
      })
    },
    resetFilter() {
      this.name = ''
      this.stockFrom = ''
      this.stockTo = ''
      this.soldFrom = ''
      this.soldTo = ''
      this.getProduct()
    },
    formatPrice(value) {
      const val = value
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    checkProduct(method, id) {
      komshipAxiosIns.get(`/v1/product/detail/${id}`).then(res => {
        const { is_kompack } = res.data.data
        // eslint-disable-next-line camelcase
        if (is_kompack === 0) {
          if (method === 'edit') {
            this.checkIsBundlingComponents('edit', 'nonBundling', id)
          } else {
            this.checkIsBundlingComponents('delete', 'nonBundling', id)
          }
        } else if (method === 'edit') {
          this.$swal({
            title: 'Tidak dapat edit produk',
            text: 'Maaf, produk tidak dapat diedit karena terhubung dengan Gudang kompack',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Tidak dapat hapus produk',
            text: 'Maaf, produk tidak dapat dihapus karena terhubung dengan Gudang kompack',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkBundling(method, id) {
      komshipAxiosIns.get(`/v1/product/bundling/check-kompack?product_id=${id}`).then(res => {
        const { is_kompack } = res.data.data
        // eslint-disable-next-line camelcase
        if (is_kompack === 0) {
          if (method === 'edit') {
            this.$router.push({ name: this.$route.meta.routeEditBundling, params: { product_id: id } })
          } else {
            this.confirmDelete('bundling', id)
          }
        } else if (method === 'edit') {
          this.$swal({
            title: 'Tidak dapat edit bundling',
            text: 'Maaf, produk bundlingmu terhubung dengan gudang kompack. kamu tidak bisa melakukan edit produk bundling',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Tidak dapat hapus produk',
            text: 'Maaf, produk bundlingmu terhubung dengan gudang kompack. kamu tidak bisa melakukan hapus produk bundling',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkIsBundlingComponents(method, bundling, id) {
      komshipAxiosIns.get(`/v1/product/bundling/check-related/${id}`).then(res => {
        const { is_bundling_related } = res.data.data
        // eslint-disable-next-line camelcase
        if (is_bundling_related === 0) {
          if (method === 'edit') {
            if (bundling === 'nonBundling') {
              this.$router.push({ name: this.$route.meta.routeEdit, params: { product_id: id } })
            } else {
              this.$router.push({ name: this.$route.meta.routeEditBundling, params: { product_id: id } })
            }
          } else if (method === 'delete') {
            if (bundling === 'nonBundling') {
              this.confirmDelete('nonBundling', id)
            } else {
              this.confirmDelete('bundling', id)
            }
          }
        } else if (method === 'edit') {
          this.$swal({
            title: 'Tidak dapat edit bundling',
            text: 'Maaf, produk tidak dapat diedit karena terhubung dengan produk bundling',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Tidak dapat hapus produk',
            text: 'Maaf, produk tidak dapat dihapus karena terhubung dengan produk bundling',
            icon: 'warning',
            iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Oke',
            customClass: {
              icon: 'border-0 mb-[16px]',
              cancelButton: 'btn btn-primary w-full',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    confirmDelete(bundling, id) {
      this.$swal({
        title: 'Kamu yakin menghapus produk ini?',
        text: 'Produk yang terhapus tidak akan dapat dikembalikan lagi',
        icon: 'warning',
        iconHtml: '<img src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png">',
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: 'Batal',
        confirmButtonText: 'Iya',
        reverseButtons: true,
        customClass: {
          icon: 'border-0 mb-[16px]',
          cancelButton: 'btn btn-outline-primary w-[48%] mr-[2%]',
          confirmButton: 'btn btn-primary w-[48%] ml-[2%]',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (bundling === 'nonBundling') {
            this.deleteProduct(id)
          } else if (bundling === 'bundling') {
            this.deleteBundling(id)
          }
        }
      })
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
* {
  font-family: Poppins;
}
[dir] .background-table-variant {
  background: #FFF;
}
@media only screen and (max-width: 922px) {
    [dir] .table-list-product {
        display: none;
    }
}
@media only screen and (min-width: 923px) {
    [dir] .table-list-product {
        display: inline-block;
    }
    [dir] .table-list-product-mobile {
        display: none;
    }
}
[dir] .when-closed {
  display: inline-block;
}
[dir] .when-opened {
  display: inline-block;
}
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}
@media only screen and (min-width: 991px) {
  [dir] .wrapper__filter__data__product__mobile {
    display: none!important;
  }
}
@media only screen and (max-width: 990px) {
  [dir] .wrapper__filter__data__product {
    display: none!important;
  }
  [dir] .wrapper__form__filter__data__product__mobile {
    width: 270px!important;
  }
}
</style>
